import common from '../common';
import errors from './errors';
import themes from './theme';
import features from '../en-US/features';

export default {
  ...common,
  mobileTicket: 'Online Leveringsbon',
  ...features,
  save: 'Opslaan',
  username: 'Gebruikersnaam',
  password: 'Wachtwoord',
  login: 'Aanmelden',
  required: 'Verplicht',
  light: 'Licht',
  dark: 'Donker',
  rememberMe: 'Onthoud me',
  eula: 'EULA',
  copyright: '© {year} - Command Alkon, Inc.',
  privacyPolicy: 'Privacybeleid',
  customer: 'Klant',
  project: 'Project',
  isBillable: 'Factureerbaar',
  dispatchDateTime: 'Datum',
  orderId: 'Bestelling',
  ticketId: 'Levering',
  location: 'Locatie',
  vehicleTypeName: 'Wagen',
  product: 'Product',
  products: 'Product',
  salesTax: 'Belasting',
  totalPrice: 'Totaal',
  destination: 'Bestemming',
  productId: 'Product ID',
  statusCode: 'Status',
  vehicleTypeId: 'Wagen Type ID',
  locationId: 'Locatie ID',
  customerId: 'Klant ID',
  projectId: 'Project ID',
  dispatchTime: 'Tijd',
  showAll: 'Toon alles',
  hideAll: 'Verberg alles',
  undoChanges: 'Wijzigingen ongedaan maken',
  resetColumns: 'Kolommen terugzetten',
  displayRowCount: `Weergave {count, plural, 
    =0 {0 rijen} 
    one {# rij} 
    other {# rijen} 
  }`,
  columnFilters: 'Kolomfilters',
  visible: 'Zichtbaar',
  hidden: 'Verborgen',
  itemDetail: 'Item details',
  quantity: 'Hoeveelheid',
  quantityMaxWaterToAdd: 'Hoeveelheid (Maximaal water om toe te voegen: {maxWaterQuantity})',
  unloadingMethod: 'Losmethode',
  time: 'Tijd',
  eventStatus: 'Status',
  MTQ_text: 'Kubieke meter',
  M3_text: 'Kubieke meter',
  EA_text: 'Elk',
  LTR_text: 'Liter',
  YDQ_text: 'Kubieke meter',
  GLL_text: 'Gallons',
  ACR_text: 'Acres',
  HUR_text: 'Uur',
  INH_text: 'Inches',
  FOT_text: 'Voet',
  YRD_text: 'Tuin',
  MMT_text: 'Millimeter',
  CMT_text: 'Centimeter',
  MTR_text: 'Meter',
  SMI_text: 'Mijl (Statuutmijl)',
  KMT_text: 'Kilometer',
  INK_text: 'Vierkante inch',
  FTK_text: 'Vierkante voet',
  YDK_text: 'Vierkante tuin',
  MIK_text: 'Vierkante mijl',
  CMK_text: 'Vierkante centimeter',
  MTK_text: 'Vierkante meter',
  KMK_text: 'Vierkante kilometer',
  INQ_text: 'Kubieke inch',
  MMQ_text: 'Kubieke millimeter',
  FTQ_text: 'Kubieke voet',
  CMQ_text: 'Kubieke centimeter',
  OZA_text: 'Vloeibare ons',
  PT_text: 'Pint',
  QT_text: 'Kwart',
  MLT_text: 'Milliliter',
  ONZ_text: 'Ons',
  LBR_text: 'Pond',
  STN_text: 'Ton',
  GRM_text: 'Gram',
  KGM_text: 'Kilogram',
  TNE_text: 'Ton',
  PS_text: 'PSI',
  MPA_text: 'Megapascal',
  C56_text: 'Newton per vierkante millimeter',
  C62_text: 'Een',
  NL_text: 'Laden',
  MT_text: 'Mat',
  P1_text: 'Procent',
  CEL_text: 'Graad Celsius',
  FAH_text: 'Graad Fahrenheit',
  BLL_text: 'Loop',
  CLT_text: 'Centiliter',
  DLT_text: 'Deciliter',
  GLI_text: 'Gallon',
  '2U_text': 'Megagram',
  SA_text: 'Zak',
  '60_text': 'Procent gewicht',
  BG_text: 'Tas',
  SEC_text: 'Seconde',
  MIN_text: 'Minuut',
  '64_text': 'Pond per vierkante inch - meter',
  SP_text: 'Plankpakket',
  MMK_text: 'Vierkante millimeter',
  MTN_text: 'Metrische ton',
  GT_text: 'Gigaton',
  '/NL_text': 'Per lading',
  '/EA_text': 'Per hoeveelheid',
  '/H/ + h_text': 'Per uur',
  '/YQ/ + YDQ_text': 'Per kubieke meter',
  '/MQ/ + MTQ_text': 'Per kubieke meter',
  '/MN/ + MIN_text': 'Per minuut',
  '/MT_text': 'Per meter',
  DSG_text: 'Dosering',
  '/C_text': 'Per 100 gewicht',
  '64_2_text': 'Specifiek',
  MTQ: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,
  M3: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,

  LTR: `{value, plural,
    =0 {0 Liter}
    one {1 Liter}
    other {# Liter}
  }`,

  TO: `{value, plural,
    =0 {0 Ton}
    one {1 Ton}
    other {# Ton}
  }`,

  ITEM: `{value, plural,
    =0 {0 Artikelen}
    one {1 Item}
    other {# Artikelen}
  }`,

  YDQ_short: `{value, plural,
    =0 {0 CY}
    one {1 CY}
    other {# CY}
  }`,

  YDQ: `{value, plural,
    =0 {0 Kubieke meter}
    one {1 Kubieke tuin}
    other {# Kubieke meter}
  }`,

  GLL: `{value, plural,
    =0 {0 Gallons}
    one {1 Gallon}
    other {# Gallons}
  }`,

  EA: `{value, plural,
    =0 {0 Elk}
    one {1 Elk}
    other {# Elk}
  }`,

  MIN: `{value, plural,
    =0 {0 Notulen}
    one {1 Minuut}
    other {# Notulen}
  }`,

  ACR: `{value, plural,
    =0 {0 Acres}
    one {1 Akko}
    other {# Acres}
  }`,
  HUR: `{value, plural,
    =0 {0 Uur}
    one {1 Uur}
    other {# Uur}
  }`,
  INH: `{value, plural,
    =0 {0 Inches}
    one {1 Duim}
    other {# Inches}
  }`,
  FOT: `{value, plural,
    =0 {0 Voeten}
    one {1 Voet}
    other {# Voeten}
  }`,
  YRD: `{value, plural,
    =0 {0 Werven}
    one {1 Tuin}
    other {# Werven}
  }`,
  MMT: `{value, plural,
    =0 {0 Millimeters}
    one {1 Millimeter}
    other {# Millimeters}
  }`,
  CMT: `{value, plural,
    =0 {0 Centimeters}
    one {1 Centimeter}
    other {# Centimeters}
  }`,
  MTR: `{value, plural,
    =0 {0 Meters}
    one {1 Meter}
    other {# Meters}
  }`,
  SMI: `{value, plural,
    =0 {0 Mijlen (Statute Mijlen)}
    one {1 Mijl (Statute Mijl)}
    other {# Mijlen (Statute Mijlen)}
  }`,
  KMT: `{value, plural,
    =0 {0 Kilometers}
    one {1 Kilometer}
    other {# Kilometers}
  }`,
  INK: `{value, plural,
    =0 {0 Vierkante Inches}
    one {1 Vierkante Inch}
    other {# Vierkante Inches}
  }`,
  FTK: `{value, plural,
    =0 {0 Vierkante voeten}
    one {1 Vierkante voet}
    other {# Vierkante voeten}
  }`,
  YDK: `{value, plural,
    =0 {0 Vierkante werven}
    one {1 Vierkante tuin}
    other {# Vierkante werven}
  }`,
  MIK: `{value, plural,
    =0 {0 Vierkante mijlen}
    one {1 Vierkante mijl}
    other {# Vierkante mijlen}
  }`,
  CMK: `{value, plural,
    =0 {0 Vierkante Centimeters}
    one {1 Vierkante Centimeter}
    other {# Vierkante Centimeters}
  }`,
  MTK: `{value, plural,
    =0 {0 Kubiek Meters}
    one {1 Kubiek Metre}
    other {# Kubiek Meters}
  }`,
  KMK: `{value, plural,
    =0 {0 Vierkante Kilometers}
    one {1 Vierkante Kilometer}
    other {# Vierkante Kilometers}
  }`,
  INQ: `{value, plural,
    =0 {0 Kubiek Inches}
    one {1 Kubiek Inch}
    other {# Kubiek Inches}
  }`,
  MMQ: `{value, plural,
    =0 {0 Kubiek Millimeters}
    one {1 Kubiek Millimeter}
    other {# Kubiek Millimeters}
  }`,
  FTQ: `{value, plural,
    =0 {0 Kubiek voeten}
    one {1 Kubiek voet}
    other {# Kubiek voeten}
  }`,
  CMQ: `{value, plural,
    =0 {0 Kubiek Centimeters}
    one {1 Kubiek Centimeter}
    other {# Kubiek Centimeters}
  }`,
  OZA: `{value, plural,
    =0 {0 Vloeibare ounces}
    one {1 Vloeibare ounce}
    other {# Vloeibare ounces}
  }`,
  PT: `{value, plural,
    =0 {0 Pinten}
    one {1 Pint}
    other {# Pinten}
  }`,
  QT: `{value, plural,
    =0 {0 Kwart}
    one {1 Kwart}
    other {# Kwart}
  }`,
  MLT: `{value, plural,
    =0 {0 Milliliters}
    one {1 Milliliter}
    other {# Milliliters}
  }`,
  ONZ: `{value, plural,
    =0 {0 Ons}
    one {1 Ons}
    other {# Ons}
  }`,
  LBR: `{value, plural,
    =0 {0 Ponden}
    one {1 Pound}
    other {# Ponden}
  }`,
  STN: `{value, plural,
    =0 {0 Tonnen}
    one {1 Ton}
    other {# Tonnen}
  }`,
  GRM: `{value, plural,
    =0 {0 Gram}
    one {1 Gram}
    other {# Gram}
  }`,
  KGM: `{value, plural,
    =0 {0 Kilogram}
    one {1 Kilogram}
    other {# Kilogram}
  }`,
  TNE: `{value, plural,
    =0 {0 Tonnes}
    one {1 Tonne}
    other {# Tonnes}
  }`,
  PS: `{value, plural,
    =0 {0 PSI}
    one {1 PSI}
    other {# PSI}
  }`,
  MPA: `{value, plural,
    =0 {0 Megapascal}
    one {1 Megapascal}
    other {# Megapascal}
  }`,
  C56: `{value, plural,
    =0 {0 Newtons Per Vierkante Millimeter}
    one {1 Newton Per Vierkante Millimeter}
    other {# Newtons Per Vierkante Millimeter}
  }`,
  C62: `{value, plural,
    =0 {0 Een}
    one {1 Een}
    other {# Een}
  }`,
  NL: `{value, plural,
    =0 {0 Ladingen}
    one {1 Laden}
    other {# Ladingen}
  }`,
  MT: `{value, plural,
    =0 {0 Matten}
    one {1 Mat}
    other {# Matten}
  }`,
  P1: `{value, plural,
    =0 {0 Procent}
  one {1 Procent}
  other {# Procent}
}`,
  CEL: `{value, plural,
    =0 {0 Graden Celsius}
  one {1 graden Celsius}
  other {# Graden Celsius}
}`,
  FAH: `{value, plural,
    =0 {0 Graden Fahrenheit}
  one {1 Graden Fahrenheit}
  other {# Graden Fahrenheit}
}`,
  BLL: `{value, plural,
    =0 {0 Vaten}
  one {1 Loop}
  other {# Vaten}
}`,
  CLT: `{value, plural,
    =0 {0 Centiliters}
  one {1 Centiliter}
  other {# Centiliters}
}`,
  DLT: `{value, plural,
    =0 {0 Deciliter}
  one {1 Deciliter}
  other {# Deciliter}
}`,
  GLI: `{value, plural,
    =0 {0 Gallons}
  one {1 Gallon}
  other {# Gallons}
}`,
  '2U': `{value, plural,
    =0 {0 Megagrammen}
  one {1 Megagram}
  other {# Megagrammen}
}`,
  SA: `{value, plural,
    =0 {0 Zakken}
  one {1 Zak}
  other {# Zakken}
}`,
  60: `{value, plural,
    =0 {0 Procent gewicht}
  one {1 Procent gewicht}
  other {# Procent gewicht}
}`,
  BG: `{value, plural,
    =0 {0 Zakken}
  one {1 Tas}
  other {# Zakken}
}`,
  SEC: `{value, plural,
    =0 {0 Seconden}
  one {1 Seconde}
  other {# Seconden}
}`,
  64: `{value, plural,
    =0 {0 Pond per vierkante inch - meter}
  one {1 Pond per vierkante inch - meter}
  other {# Pond per vierkante inch - meter}
}`,
  SP: `{value, plural,
    =0 {0 Plankpakketten}
  one {1 Plankpakket}
  other {# Plankpakketten}
}`,
  MMK: `{value, plural,
    =0 {0 Vierkante millimeter}
    one {1 vierkante millimeter}
    other {# vierkante millimeter}
  }`,
  MTN: `{value, plural,
    =0 {0 metrische ton}
    one {1 metrische ton}
    other {# ton}
  }`,
  GT: `{value, plural,
    =0 {0 Gigaton}
    one {1 Gigaton}
    other {# Gigaton}
  }`,
  '/NL': `{value, plural,
    =0 {0 per lading}
    one {1 per lading}
    other {# per lading}
  }`,
  '/EA': `{value, plural,
    =0 {0 per hoeveelheid}
    one {1 per hoeveelheid}
    other {# per hoeveelheid}
  }`,
  '/H/ + h': `{value, plural,
    =0 {0 per uur}
    one {1 per uur}
    other {# per uur}
  }`,
  '/YQ/ + YDQ': `{value, plural,
    =0 {0 per kubieke meter}
    one {1 per kubieke meter}
    other {# per kubieke meter}
  }`,
  '/MQ/ + MTQ': `{value, plural,
    =0 {0 Per Kubieke Meter}
    one {1 per kubieke meter}
    other {# per kubieke meter}
  }`,
  '/MN/ + MIN': `{value, plural,
    =0 {0 per minuut}
    one {1 per minuut}
    other {# per minuut}
  }`,
  '/MT': `{value, plural,
    =0 {0 Per Meter}
    one {1 Per Meter}
    other {# Per Meter}
  }`,
  DSG: `{value, plural,
    =0 {0 dosering}
    one {1 dosering}
    other {# dosering}
  }`,
  '/C': `{value, plural,
    =0 {0 Per 100 gewicht}
    one {1 Per 100 gewicht}
    other {# Per 100 gewicht}
  }`,
  '64_2': `{value, plural,
    =0 {0 Specifiek}
    one {1 Specifiek}
    other {# Specifiek}
  }`,
  LTR_short: `{value, plural,
  =0 {0 L}
  one {1 L}
  other {# L}
}`,
  TO_short: `{value, plural,
  =0 {0 TN}
  one {1 TN}
  other {# Tn}
}`,
  ITEM_short: `{value, plural,
    =0 {0 Artikelen}
    one {1 Item}
    other {# Artikelen}
}`,
  GLL_short: `{value, plural,
  =0 {0 GAL}
  one {1 GAL}
  other {# GAL}
}`,
  EA_short: `{value, plural,
  =0 {0 EA}
  one {1 EA}
  other {# EA}
}`,
  MIN_short: `{value, plural,
  =0 {0 MIN}
  one {1 MIN}
  other {# MIN}
}`,
  ACR_short: `{value, plural,
  =0 {0 AC}
  one {1 AC}
  other {# AC}
}`,
  HUR_short: `{value, plural,
  =0 {0 HR}
  one {1 HR}
  other {# HR}
}`,
  INH_short: `{value, plural,
  =0 {0 IN}
  one {1 IN}
  other {# IN}
}`,
  FOT_short: `{value, plural,
  =0 {0 FT}
  one {1 FT}
  other {# FT}
}`,
  YRD_short: `{value, plural,
  =0 {0 YD}
  one {1 YD}
  other {# YD}
}`,
  MMT_short: `{value, plural,
  =0 {0 MM}
  one {1 MM}
  other {# MM}
}`,
  CMT_short: `{value, plural,
  =0 {0 CM}
  one {1 CM}
  other {# CM}
}`,
  MTR_short: `{value, plural,
  =0 {0 M}
  one {1 M}
  other {# M}
}`,
  SMI_short: `{value, plural,
  =0 {0 Miles (ST MI)}
  one {1 Mile ((ST MI)}
  other {# Miles ((ST MI)}
}`,
  KMT_short: `{value, plural,
  =0 {0 KM}
  one {1 KM}
  other {# KM}
}`,
  INK_short: `{value, plural,
  =0 {0 SQ IN}
  one {1 SQ IN}
  other {# SQ IN}
}`,
  FTK_short: `{value, plural,
  =0 {0 FT2}
  one {1 FT2}
  other {# FT2}
}`,
  YDK_short: `{value, plural,
  =0 {0 SQ YD}
  one {1 SQ YD}
  other {# SQ YD}
}`,
  MIK_short: `{value, plural,
  =0 {0 SQ MI}
  one {1 SQ MI}
  other {# SQ MI}
}`,
  CMK_short: `{value, plural,
  =0 {0 CM2}
  one {1 CM2}
  other {# CM2}
}`,
  MTK_short: `{value, plural,
  =0 {0 M3}
  one {1 M3}
  other {# M3}
}`,
  KMK_short: `{value, plural,
  =0 {0 KM2}
  one {1 KM2}
  other {# KM2}
}`,
  INQ_short: `{value, plural,
  =0 {0 CU IN}
  one {1 CU IN}
  other {# CU IN}
}`,
  MMQ_short: `{value, plural,
  =0 {0 MM3}
  one {1 MM3}
  other {# MM3}
}`,
  FTQ_short: `{value, plural,
  =0 {0 FT3}
  one {1 FT3}
  other {# FT3}
}`,
  CMQ_short: `{value, plural,
  =0 {0 CM3}
  one {1 CM3}
  other {# CM3}
}`,
  OZA_short: `{value, plural,
  =0 {0 FL OZ}
  one {1 FL OZ}
  other {# FL OZ}
}`,
  PT_short: `{value, plural,
  =0 {0 PT}
  one {1 PT}
  other {# PT}
}`,
  QT_short: `{value, plural,
  =0 {0 QT}
  one {1 QT}
  other {# QT}
}`,
  MLT_short: `{value, plural,
  =0 {0 mL}
  one {1 mL}
  other {# mL}
}`,
  ONZ_short: `{value, plural,
  =0 {0 OZ}
  one {1 OZ}
  other {# OZ}
}`,
  LBR_short: `{value, plural,
  =0 {0 LB}
  one {1 LB}
  other {# LB}
}`,
  STN_short: `{value, plural,
  =0 {0 TN}
  one {1 TN}
  other {# TN}
}`,
  GRM_short: `{value, plural,
  =0 {0 G}
  one {1 G}
  other {# G}
}`,
  KGM_short: `{value, plural,
  =0 {0 KG}
  one {1 KG}
  other {# KG}
}`,
  TNE_short: `{value, plural,
  =0 {0 TN}
  one {1 TN}
  other {# TN}
}`,
  PS_short: `{value, plural,
  =0 {0 PSI}
  one {1 PSI}
  other {# PSI}
}`,
  MPA_short: `{value, plural,
  =0 {0 MPa}
  one {1 MPa}
  other {# MPa}
}`,
  C56_short: `{value, plural,
    =0 {0 Newtons Per Vierkante Millimeter}
    one {1 Newton Per Vierkante Millimeter}
    other {# Newtons Per Vierkante Millimeter}
}`,
  C62_short: `{value, plural,
    =0 {0 Een}
    one {1 Een}
    other {# Een}
}`,
  NL_short: `{value, plural,
    =0 {0 Ladingen}
    one {1 Laden}
    other {# Ladingen}
}`,
  MT_short: `{value, plural,
  =0 {0 Mats}
  one {1 Mat}
  other {# Mats}
}`,
  P1_short: `{value, plural,
  =0 {0 %}
  one {1 %}
  other {# %}
}`,
  CEL_short: `{value, plural,
  =0 {0 C}
  one {1 C}
  other {# C}
}`,
  FAH_short: `{value, plural,
  =0 {0 F}
  one {1 F}
  other {# F}
}`,
  BLL_short: `{value, plural,
  =0 {0 BBL}
  one {1 BBL}
  other {# BBL}
}`,
  CLT_short: `{value, plural,
  =0 {0 cL}
  one {1 cL}
  other {# cL}
}`,
  DLT_short: `{value, plural,
  =0 {0 dL}
  one {1 dL}
  other {# dL}
}`,
  GLI_short: `{value, plural,
  =0 {0 GAL}
  one {1 GAL}
  other {# GAL}
}`,
  '2U_short': `{value, plural,
    =0 {0 Megagrammen}
  one {1 Megagram}
  other {# Megagrammen}
}`,
  SA_short: `{value, plural,
     =0 {0 Zakken}
  one {1 Zak}
  other {# Zakken}
}`,
  '60_short': `{value, plural,
    =0 {0 Procent gewicht}
  one {1 Procent gewicht}
  other {# Procent gewicht}
}`,
  BG_short: `{value, plural,
    =0 {0 Zakken}
  one {1 Tas}
  other {# Zakken}
}`,
  SEC_short: `{value, plural,
  =0 {0 SEC}
  one {1 SEC}
  other {# SEC}
}`,
  '64_short': `{value, plural,
    =0 {0 Pond per vierkante inch - meter}
  one {1 Pond per vierkante inch - meter}
  other {# Pond per vierkante inch - meter}
}`,
  SP_short: `{value, plural,
    =0 {0 Plankpakketten}
  one {1 Plankpakket}
  other {# Plankpakketten}
}`,
  MMK_short: `{value, plural,
    =0 {0 Vierkante millimeter}
    one {1 vierkante millimeter}
    other {# vierkante millimeter}
  }`,
  MTN_short: `{value, plural,
  =0 {0 MT}
  one {1 MT}
  other {# MT}
}`,
  GT_short: `{value, plural,
  =0 {0 GT}
  one {1 GT}
  other {# GT}
}`,
  '/NL_short': `{value, plural,
=0 {0 /Laden}
one {1 /Laden}
other {# /Laden}
}`,
  '/EA_short': `{value, plural,
=0 {0 /EA}
one {1 /EA}
other {# /EA}
}`,
  '/H/ + h_short': `{value, plural,
=0 {0 /Uur}
one {1 /Uur}
other {# /Uur}
}`,
  '/YQ/ + YDQ_short': `{value, plural,
=0 {0 /CY}
one {1 /CY}
other {# /CY}
}`,
  '/MQ/ + MTQ_short': `{value, plural,
=0 {0 /M3}
one {1 /M3}
other {# /M3}
}`,
  '/MN/ + MIN_short': `{value, plural,
=0 {0 /MIN}
one {1 /MIN}
other {# /MIN}
}`,
  '/MT_short': `{value, plural,
=0 {0 /M}
one {1 /M}
other {# /M}
}`,
  DSG_short: `{value, plural,
=0 {0 Dosering}
one {1 Dosering}
other {# Dosering}
}`,
  '/C_short': `{value, plural,
=0 {0 /C}
one {1 /C}
other {# /C}
}`,
  '64_2_short': `{value, plural,
=0 {0 Specifiek}
one {1 Specifiek}
other {# Specifiek}
}`,
  mixIdLabel: 'Mengsel ID/Omschrijving',
  orderedQtyLabel: 'Bestelde hoeveelheid',
  loadedQtyLabel: 'Geladen Hoeveelheid',

  addedByCustomer: 'Toegevoegd op vraag van de klant',

  PRINTED: 'Toegewezen',
  LOADING_STARTED: 'Start laden',
  LOADING_COMPLETE: 'Einde laden',
  TO_JOB: 'Centrale verlaten',
  ARRIVE_JOB: 'Aankomst op bouwplaats',
  UNLOADING: 'Start lossen',
  END_UNLOADING: 'Einde Lossen',
  LEAVE_JOB: 'Bouwplaats verlaten',
  IN_YARD: 'Op centrale',
  PUMPING_STARTED: 'Start pompen',
  PUMPING_COMPLETE: 'Einde pompen',
  READY_TO_PUMP: 'Klaar om te pompen',
  FIRST_WCCONTACT: 'Water cement contact',

  plantName: 'Naam fabriek',
  plantAddress: 'Adres fabriek',
  loadingScheduleStart: 'Geplande laadtijd',

  deliveryAddress: 'Leveringsadres',
  phoneNumber: 'Telefoonnummer',
  unloadingScheduleStart: 'Geplande lostijdstip',
  agreedUnloading: 'Gevraagde lostijdstip',

  deliveryInformation: 'Informatie over de levering',
  next: 'Volgende',

  material: 'Materiaal',
  loadingPoint: 'Laadpunt',
  unloadingPoint: 'Lospunt',

  comment: 'Commentaar',

  sapDeliveryNumber: 'SAP leveringsnummer (BCC-nummer)',
  salesOrderNumber: 'Verkoopordernummer en artikelregel',
  wcDateTime: 'Datum en tijd W/C contact',
  po: 'PO Nummer',
  orderedByName: 'Bestelling geplaatst door naam',
  orderedByPhone: 'Bestelling geplaatst door telefoonnummer',
  resourceId: 'Wagen ID',
  totalLoadedQty: 'Totaal geladen hoeveelheid inclusief deze lading',
  spacing: 'Laad interval',
  deliveryFlow: 'Leveringstempo',
  timeOnSite: 'Totale tijd ter plaatse/geplande lostijd',
  salesText: 'Verkooptekst (materiaal-ID)',
  strengthClass: 'Sterkteklasse',
  environmentExposure: 'Milieublootstelling',
  metExposureClasses: 'Blootstellingsklasse bereikt',
  dmax: 'Dmax',
  standardCert: 'Norm (certificering)',
  workabilityTarget: 'Doelstelling verwerkbaarheid klant',
  stoneQuality: 'Steen kwaliteit',
  sandQuality: 'Zand kwaliteit',
  targetWC: 'Gewenste W/C-verhouding uit recept',
  controlClass: 'Controle klasse',
  chlorideClass: 'Controle klasse',
  typeAndStrength1: 'Type cement en sterkteklasse 1',
  typeAndStrength2: 'Type cement en sterkteklasse 2',
  typeOfAddition: 'Type toevoeging',
  typeOfAdmixture: 'Type toevoeging',
  orderedTemp: 'Geordende temperatuur',
  fibers1: 'Type en inhoud van de vezels 1',
  fibers2: 'Type en inhoud van de vezels 2',
  calculatedMaturityTime: 'Berekende rijpingstijd uit W/C-contacttijd',

  mixIdentity: 'Mix Identity',
  grade: 'Grade',
  mixType: 'Mix Type',
  maxAggSize: 'Max Agg Size',
  maxAggType: 'Max Agg Type',
  cementType: 'Cement Type',
  admixtures: 'Admixtures',
  addmixDescription: 'Addmix Description',
  slump: 'Slump',
  minCementContent: 'Min Cement Content',
  maxWatCemRatio: 'Max Water/Cement Ratio',
  maxWaterToAdd: 'Max Water To Add',
  mortarMinWorkingLife: 'Mortar Min Working Life',
  code: 'Code',
  dcClass: 'dcClass',
  chloride: 'Chloride',
  signedByHanson: 'Signed By Hanson',
  warmStamp: 'Warm Stamp',
  coldStamp: 'Cold Stamp',
  containsSikatard: 'Contains Sikatard',
  containsDelvo: 'Contains Delvo',

  generalDelivery: 'Algemene leveringsinformatie',
  materialInfo: 'Materiaal informatie',

  water: 'Water',
  materialService: 'Materiaal / Service',
  returnedMaterial: 'Geretourneerd materiaal',
  returnedConcrete: 'Returned Concrete',

  waterAdded: 'Water toegevoegd',
  concreteOnTruck: 'Beton in de truck',
  concreteOnTruckUom: 'Default beton in de truck UOM',
  reason: 'Reden',
  customerAddedWater: 'Klant heeft water toegevoegd',
  addWater: 'Water toegevoegd',
  addedAt: 'Toegevoegd om',

  addMaterialService: 'Materiaal/dienst toevoegen',
  customerAddedProduct: 'Klant heeft product toegevoegd',

  addReturnedMaterial: 'Geretourneerd materiaal toevoegen',
  leftover: 'Overgebleven',

  submitAcceptance: 'Indienen Aanvaarding',
  accepted: 'Aanvaard',
  rejected: 'Afgewezen',
  customerAcceptance: 'Aanvaarding door klant',
  finalize: 'Afronden',

  materialSummary: 'Samenvatting materiaal',
  mix: 'Meng',
  ordered: 'Besteld',
  loaded: 'Geladen hoeveelheid',
  totalOrdered: 'Geladen hoeveelheid',
  loadedOrderQuantity: 'Totale geladen hoeveelheid',
  loadedOrder: 'Reeds geladen',

  driverComment: 'Commentaar chauffeur',
  customerComment: 'Commentaar van de klant',
  editComment: 'Bewerk commentaar',
  addComment: 'Commentaar toevoegen',
  additionalInfo: 'Extra Info',
  onBoard: 'Aan boord',
  driver: 'Bestuurder',
  commentInstruction: 'Commentaar Instructie',
  signatureInstruction: 'Handtekening klant',
  customerSignature: 'Handtekening klant',

  general: 'Algemeen',

  status: 'Status',
  updateStatusTime: 'Status bijwerken Tijd',
  delete: 'Verwijderen',
  cancel: 'Annuleren',
  back: 'Terug',
  add: 'Toevoegen',
  done: 'klaar',
  ok: 'OK',
  decline: 'Afwijzen',
  Submit: 'Indienen',
  submit: 'Indienen',
  Time: 'Tijd',
  clear: 'Duidelijk',
  lastRefresh: 'Laatst verniewd',
  refreshEnabled: 'Vernieuwen inschakelen',

  id: 'ID',
  name: 'Naam',
  invoiceable: 'Factureerbaar',
  provideSignature: 'Verstrek uw handtekening',
  ticket: 'Leveringsbon',
  details: 'Details',
  qrCode: 'QR-code',

  driverAcceptanceReason: 'Reden',
  driverAcceptance: 'Acceptatie bestuurder',

  'payment-method': 'Betaalmethoden',
  'add-payment-method': 'Betaalmethode toevoegen',

  'reason-code': 'Code Reden',
  'add-reason-code': 'Code Reden toevoegen',

  'qr-code': 'QR-code',
  'unloading-method': 'Losmethoden',
  'add-unloading-method': 'Voeg een losmethode toe',

  'driver-comment': 'Opmerkingen chauffeur',
  'add-driver-comment': 'Voeg een Opmerking chauffeur toe',
  'driver-sellable': 'Chauffeur verkoopbaar',

  vehicle: 'Voertuigen',
  'add-vehicle': 'Voertuig toevoegen',

  'vehicle-type': 'Voertuigtypes',
  vehicleTypes: 'Voertuigtypes',
  vehicleType: 'Voertuigtype',
  'add-vehicle-type': 'Voertuigtype toevoegen',
  countries: 'Landen',
  country: 'Land',

  type: 'Type',
  uom: 'UOM',
  mandatory: 'Verplicht',

  qrTemplate: 'QR Code Sjabloon',
  includeMixInfo: 'Mix Info opnemen in QR Code',
  includeTicketEvents: 'Leveringsgebeurtenissen opnemen in QR Code',

  quantityField: 'Hoeveelheid veld inschakelen',
  concreteOnTruckField: 'Beton op vrachtwagenveld inschakelen',
  materialOnTruckField: 'Materiaal op vrachtwagenveld inschakelen',
  timeField: 'Tijd veld inschakelen',
  reasonField: 'Reden veld inschakelen',

  driverInstructions: 'Bestuurdersinstructies',

  waitingForTicket: 'Wachten op kaartje...',
  createTestTicketForTruck: 'Maak een testticket voor vrachtwagen',

  exceedsLoadedQuantity: 'Waarde kan de geladen hoeveelheid van {loadedQuantity} niet overschrijden.',
  maxQuantity: 'Maximumhoeveelheid',
  maxQuantityError: 'De hoeveelheid kan de gedefinieerde maximumhoeveelheid van {maxQty} niet overschrijden',
  maxValueError: 'Value cannot exceed defined maximum of {maxQty}',
  minValueError: 'Value cannot be less than defined minimum of {minQty}',

  notAuthorized: 'Niet geautoriseerd',

  enterTruckNumber: 'Vrachtwagennummer invoeren',
  setTruckNumber: 'Vrachtwagennummer instellen',

  commentList: 'Commentaarlijst',
  list: 'Lijst',

  gridPreferences: 'Raster Voorkeuren',

  loadingUnloadingPoints: 'Laad-/losplaatsen',

  activity: 'Activiteit',

  crn: 'Crn',
  update: 'Update',

  CONCRETE: 'Concrete',
  WATER: 'Water',
  ADDITIVE: 'Additive',

  'submit-signature-button': 'Accepteren',
  'cancel-signature-button': 'Annuleren',

  uomCode: 'Standaard UOM',
  'product-type': 'Producttypes',
  'add-product-type': 'Producttype toevoegen',
  'add-product': 'Product toevoegen',
  'setup_driver-comment': 'Opmerkingen van de chauffeur',
  setup_product: 'Producten',
  'setup_product-type': 'Producttypes',
  'setup_qr-code': 'QR Code',
  'setup_reason-code': 'Code reden',
  'setup_unloading-method': 'Losmethoden',
  'setup_entity-mt': 'Bedrijf',
  setup_vehicle: 'Voertuigen',
  'setup_vehicle-type': 'Voertuigtypes',
  'setup_data-retentions': 'Data Retention',
  'add-data-retentions': 'Add Data Retention',
  'data-retentions': 'Data Retention',
  setup_carriers: 'Carriers',
  'add-carriers': 'Add Carriers',
  carriers: 'Carriers',
  'setup_concrete-classes': 'Material Compliance',
  'add-concrete-classes': 'Add Material Compliance',
  'concrete-classes': 'Material Compliance',
  'setup_forms-and-waivers': 'Forms & Waivers',
  'add-forms-and-waivers': 'Add Forms & Waivers',
  'forms-and-waivers': 'Forms & Waivers',

  companyFleet: 'Company Fleet',

  category: 'Category',
  interval: 'Interval',
  orders: 'Orders And Tickets',
  '30days': '30 Days',
  '60days': '60 Days',
  '90days': '90 Days',
  '1year': '1 Year',
  '3years': '3 Years',

  waterMandatory: 'Water Mandatory',

  qrCodeTemplate: 'QR Code Template',

  waterConfig: 'Optionele velden',

  waterProduct: 'Water',
  waterProductType: 'Type waterproduct',
  defaultWaterProduct: 'Standaard waterproduct',

  invalidTemplate: 'Ongeldige sjabloon',

  siteAddress: 'Adress losplaats',
  siteContactName: 'Naam sitecontact',
  siteContactPhoneNumber: 'Telefoonnummer sitecontact',

  'clear-signature-button': 'Handtekening wissen',

  customerCommentEnabled: 'Opmerking klant inschakelen',
  driverCommentEnabled: 'Opmerking chauffeur inschakelen',

  driverCommentMaxCharacters: 'Max Lengte',
  customerCommentMaxCharacters: 'Max Lengte',

  timeZone: 'Tijdzone',

  driverDidNotAdd: 'Werd niet toegevoegd',
  maxLineItems: 'Max aantal regelitems',
  oneLineItem: 'Moet minstens 1 regelitem hebben',

  signatureSiteContact: 'Klantnaam',
  ticketEvents: 'Ticketgebeurtenissen',
  hideEvent: 'Verberg de gebeurtenis',
  editable: 'Bewerkbaar',

  ticketDetail: `Ticket #{value}`,
  statusTimes: 'Status Tijden',

  materialAndDeliveryInfo: 'Materiaal en leveringsinformatie',

  customerRejection: 'Afwijzing klant',
  'submit-rejection-button': 'Afwijzing indienen',
  accept: 'Accepteren',
  reject: 'Lading weigeren',

  signature: 'Handtekening',
  'no-signature': 'Geen handtekening',

  unFinalizePassword: 'Un-Finalize Wachtwoord',

  waterInWorkflow: 'Toevoeging van water moet verplicht ingevuld worden',
  multicountry: 'Multicountry',

  timestampAfterSequence: 'WAARSCHUWING: Tijd mag niet voor het vorige tijdstip vallen',
  timestampBeforeSequence: 'WAARSCHUWING: Tijdstip mag niet na het volgende tijdstip vallen',

  noRestQuantity: 'Geen geretourneerd materiaal',

  returnedMaterialMandatory: 'Geretourneerde hoeveelheid moet verplicht ingevuld worden',

  driverCommentMaxChars: 'Length cannot exceed max of {maxLength} characters.',

  QrCodeEnabled: 'Enable QR Code',

  QrMethod_linkBased: 'Op koppeling gebaseerd',

  QrMethod_contentBased:
    'Op inhoud gebaseerd, OPMERKING: Op inhoud gebaseerde QR-codes hebben tekenbeperkingen die problemen kunnen veroorzaken die groter zijn dan 1024 tekens.',

  QrMethod_contentBasedWarning:
    'OPMERKING: deze QR-code bevat {amount} tekens die mogelijk niet leesbaar zijn door sommige telefoons.',

  returnedMaterialInWorkflow: 'Enable Returned Material Step in Workflow',

  materialServiceInWorkflow: 'Enable Material/Service Step in Workflow',

  totalPumpedQuantity: 'Total Pumped Quantity',

  concrete: 'Concrete',

  materialComplianceEnabled: 'Enable Material Compliance',

  pumpStatusTimes: 'Pump Status Times',

  pollingInterval: 'Polling Interval (seconds)',

  tenSecs: 'Interval must be at least 10 seconds',

  materialCompliance: 'Material Compliance',
  materialCompliance_notFound: 'Materiaalcompliance niet gevonden',
  consistenceValue: 'Consistentie - Inzinking',
  consistenceIsCompliant: 'Consistence complies with BS8500-2',
  airContentValue: 'Air content',
  airContentIsCompliant: 'Air Content complies with BS8500-2',

  consistenceIsCompliantShort: 'Consistence complies',
  airContentIsCompliantShort: 'Air Content complies',
  compliance: 'Material Compliance with BS8500-2',

  termsAndConditionsEnabled: 'Enable Terms and Conditions form',
  termsAndConditions: 'Terms and Conditions',
  damageWaiverEnabled: 'Enable Damage Waiver form',
  damageWaiver: 'Damage Waiver',
  damageWaiverAcknowledged: 'Schade erkend',
  dynamicWaiver: 'Dynamic Waiver',
  dynamicWaiverEnabled: 'Enable Dynamic Waiver form',
  ticketListEnabled: 'Enable Ticket List',
  clearTicketAfterSubmission: 'Ticket wissen na indiening',
  keepTicketOpenAfterSubmission: 'Ticket open houden na indiening',
  displayTicketList: 'Display Ticket List',

  supportedLanguages: 'Supported Languages',
  allow: 'Allow',
  default: 'Default',
  isCertificationLossTriggerForReason: 'Oorzaken verlies van certificering',
  isCertificationLossTriggerForProduct: 'Oorzaken verlies van certificering',
  translations: 'Vertalingen',

  family_name: 'Last Name',
  given_name: 'First Name',
  email: 'Email',
  role: 'Role',
  inviteUser: 'Invite User',
  carrier: 'Carrier',
  emailOrPhoneNumber: 'Email',
  usersEditor: 'User Editor',
  verifyPassword: 'Verify Password',
  verificationCode: 'Verification Code',
  register: 'Register',
  registerInstructions: 'Please fill out this form to finalize your registration.',
  'password-requirements':
    'Your password must be at least 8 characters, contain at least 1 uppercase, 1 lowercase, 1 number and 1 special character.',
  passwordMinCharacters: 'Password must contain 8 or more characters.',
  passwordMaxCharacters: 'Password cannot contain more than 99 characters.',
  passwordLowerCase: 'Password must include 1 or more lower case letter.',
  passwordUpperCase: 'Password must include 1 or more upper case letter.',
  passwordNumber: 'Password must include 1 or more numeric character.',
  passwordSpecialCharacter: 'Password must include 1 or more special character.',
  passwordsDontMatch: "Passwords don't match!",
  success: 'Success!',
  'registration-success': 'Your registration was successful.',
  'post-registration-instructions': "You've completed the registration process. You may now log in.",
  createDate: 'Create Date',
  customerPo: 'Customer PO Number',
  specification: 'Specification',

  description: 'Description',
  classType: 'Class Type',
  minimumValue: 'Minimum Value',
  maximumValue: 'Maximum Value',

  TrackIt: 'TrackIt',
  trackIt3PContext: 'TrackIt 3P Context',
  inviteLanguage: 'Invite Language',
  truckNumber: 'Truck Number',
  adverseTemperature:
    'Ambient temperature at the time of mixing was such that we cannot guarantee that the concrete will be in the temperature ranges of 5-35oC as specified in EN 206.',
  environmentalNote:
    'This load contains an environmental agent which does not exceed 0.03% by weight of the concrete. Water containing this material for the manufacture of concrete will comply with BS EN 1008.',

  unloadingMethodEnabled: 'Enable Unloading Method',

  content: 'Content',
  WARNINGS: 'Warnings',
  TERMS: 'Terms',
  DAMAGE: 'Damage',
  ADDITIONALWAIVER: 'Additional Waiver',
  DYNAMICWAIVER: 'Dynamic Waiver',

  warrantyVoided: 'Material Warranty Voided',
  materialWarrantyVoidedEnabled:
    'When Certification Loss Note Product or Product & Reason Code is used, show a Certification Loss Note at time of signature.',

  'setup_templates-mt': 'Sjablonen',
  copyFrom: 'Kopieer van...',

  source: 'Source',
  sensorUnable: 'Sensor unable to determine leftover quantity.',
  PROBE: 'Probe',
  DRIVER: 'Driver',
  DRS: 'DRS',

  waterReleaseFormEnabled: 'Enable Water Release Form',
  waterRelease: 'Water Release',
  waterReleaseAcknowledged: `Watervrijgave erkend`,

  'assessment-risk': 'Risk Assessment',
  'setup_assessment-risk': 'Risk Assessment',
  'add-assessment-risk': 'Add Risk Assessment',
  enterRecipientsButton: 'Ontvanger(s) invoeren',
  recipientsInstructions: 'Voer E-mailadres in, gescheiden door een komma',
  enter: 'Binnenkomen',
  invalidEmailAddress: 'Ongeldig e-mailadres',
  personalDataPolicy:
    'Door persoonlijke gegevens in dit formulier in te voeren, bevestigt u dat u over de noodzakelijke toestemmingen beschikt om die persoonlijke gegevens te delen',
  subcategory: 'Subcategory',
  isRequired: 'Is Required',

  riskAssessmentEnabled: 'Enable Risk Assessment',
  riskAssessment: 'Risk Assessment',
  yes: 'Ja',
  no: 'Nee',

  sourceOptionsSensors: 'Source Options (sensors)',
  sourceDcm: 'DCM',
  sourceProbe: 'Probe',

  availableCustomValues: 'Available Custom Values',

  ERR_CODE__DUPLICATE: 'Duplicate',
  ERR_CODE__DEFAULT_VALUE: 'Er is al een standaardwaarde',
  ERR_CODE__EXISTING_FORM: 'Er is al een actief formulier voor dit voertuig en type',

  availableValues: 'Available Values',
  pump: 'Pomp',

  DanskName: 'Dansk-naam',
  GermanName: 'Duitse naam',
  EnglishName: 'Engelse naam',
  BritainName: 'Groot-Brittannië Naam',
  SpanishName: 'Spaanse naam',
  FrenchName: 'Franse naam',
  NederlandsName: 'Nederlandse naam',
  NorskName: 'Norsk-naam',
  SvenskaName: 'Zweedse naam',
  ItalianName: 'Italiaans naam',
  PolishName: 'Poolse naam',

  DanskNameDefault: 'Dansk-naam (standaard)',
  GermanNameDefault: 'Duitse naam (standaard)',
  EnglishNameDefault: 'Engelse naam (standaard)',
  BritainNameDefault: 'Britse naam (standaard)',
  SpanishNameDefault: 'Spaanse naam (standaard)',
  FrenchNameDefault: 'Franse naam (standaard)',
  NederlandsNameDefault: 'Nederlandse naam (standaard)',
  NorskNameDefault: 'Norsk-naam (standaard)',
  SvenskaNameDefault: 'Svenska-naam (standaard)',
  ItalianNameDefault: 'Italiaans-naam (standaard)',
  PolishNameDefault: 'Poolse-naam (standaard)',

  DanskDescription: 'Dansk Beschrijving',
  GermanDescription: 'Duitse beschrijving',
  EnglishDescription: 'Engelse beschrijving',
  BritainDescription: 'Groot-Brittannië Beschrijving',
  SpanishDescription: 'Spaanse beschrijving',
  FrenchDescription: 'Franse beschrijving',
  NederlandsDescription: 'Nederlands Beschrijving',
  NorskDescription: 'Norsk-beschrijving',
  SvenskaDescription: 'Svenska Beschrijving',
  ItalianDescription: 'Italiaans Beschrijving',
  PolishDescription: 'Poolse beschrijving',

  DanskDescriptionDefault: 'Dansk-beschrijving (standaard)',
  GermanDescriptionDefault: 'Duitse beschrijving (standaard)',
  EnglishDescriptionDefault: 'Engelse beschrijving (standaard)',
  BritainDescriptionDefault: 'Groot-Brittannië Beschrijving (standaard)',
  SpanishDescriptionDefault: 'Spaanse beschrijving (standaard)',
  FrenchDescriptionDefault: 'Franse beschrijving (standaard)',
  NederlandsDescriptionDefault: 'Nederlands Beschrijving (standaard)',
  NorskDescriptionDefault: 'Norsk-beschrijving (standaard)',
  SvenskaDescriptionDefault: 'Svenska-beschrijving (standaard)',
  ItalianDescriptionDefault: 'Italiaans-beschrijving (standaard)',
  PolishDescriptionDefault: 'Poolse-beschrijving (standaard)',

  DanskContent: 'Dansk Content',
  GermanContent: 'German Content',
  EnglishContent: 'English Content',
  BritainContent: 'Britain Content',
  SpanishContent: 'Spanish Content',
  FrenchContent: 'French Content',
  NederlandsContent: 'Nederlands Content',
  NorskContent: 'Norsk Content',
  SvenskaContent: 'Svenska Content',
  ItalianContent: 'Italiaans Content',
  PolishContent: 'Poolse inhoud',

  DanskContentDefault: 'Dansk Content (Default)',
  GermanContentDefault: 'German Content (Default)',
  EnglishContentDefault: 'English Content (Default)',
  BritainContentDefault: 'Britain Content (Default)',
  SpanishContentDefault: 'Spanish Content (Default)',
  FrenchContentDefault: 'French Content (Default)',
  NederlandsContentDefault: 'Nederlands Content (Default)',
  NorskContentDefault: 'Norsk Content (Default)',
  SvenskaContentDefault: 'Svenska Content (Default)',
  ItalianContentDefault: 'Italiaans Content (Default)',
  PolishContentDefault: 'Poolse inhoud (Default)',

  DanskCategory: 'Dansk Category',
  GermanCategory: 'German Category',
  EnglishCategory: 'English Category',
  BritainCategory: 'Britain Category',
  SpanishCategory: 'Spanish Category',
  FrenchCategory: 'French Category',
  NederlandsCategory: 'Nederlands Category',
  NorskCategory: 'Norsk Category',
  SvenskaCategory: 'Svenska Category',
  ItalianCategory: 'Italiaans Category',
  PolishCategory: 'Poolse categorie',

  DanskCategoryDefault: 'Dansk Category (Default)',
  GermanCategoryDefault: 'German Category (Default)',
  EnglishCategoryDefault: 'English Category (Default)',
  BritainCategoryDefault: 'Britain Category (Default)',
  SpanishCategoryDefault: 'Spanish Category (Default)',
  FrenchCategoryDefault: 'French Category (Default)',
  NederlandsCategoryDefault: 'Nederlands Category (Default)',
  NorskCategoryDefault: 'Norsk Category (Default)',
  SvenskaCategoryDefault: 'Svenska Category (Default)',
  ItalianCategoryDefault: 'Italiaans Category (Default)',
  PolishCategoryDefault: 'Poolse categorie (Default)',

  DanskSubcategory: 'Dansk Subcategory',
  GermanSubcategory: 'German Subcategory',
  EnglishSubcategory: 'English Subcategory',
  BritainSubcategory: 'Britain Subcategory',
  SpanishSubcategory: 'Spanish Subcategory',
  FrenchSubcategory: 'French Subcategory',
  NederlandsSubcategory: 'Nederlands Subcategory',
  NorskSubcategory: 'Norsk Subcategory',
  SvenskaSubcategory: 'Svenska Subcategory',
  ItalianSubcategory: 'Italiaans Subcategory',
  PolishSubcategory: 'Poolse subcategorie',

  DanskSubcategoryDefault: 'Dansk Subcategory (Default)',
  GermanSubcategoryDefault: 'German Subcategory (Default)',
  EnglishSubcategoryDefault: 'English Subcategory (Default)',
  BritainSubcategoryDefault: 'Britain Subcategory (Default)',
  SpanishSubcategoryDefault: 'Spanish Subcategory (Default)',
  FrenchSubcategoryDefault: 'French Subcategory (Default)',
  NederlandsSubcategoryDefault: 'Nederlands Subcategory (Default)',
  NorskSubcategoryDefault: 'Norsk Subcategory (Default)',
  SvenskaSubcategoryDefault: 'Svenska Subcategory (Default)',
  ItalianSubcategoryDefault: 'Italiaans Subcategory (Default)',
  PolishSubcategoryDefault: 'Poolse subcategorie (Default)',
  priorSignatures: 'Prior Signatures',
  damageWaiverSignature: 'Damage Waiver Signature',
  waterReleaseSignature: 'Water Release Signature',

  errors,
  themes,
  isPDFRecipientListEnable: 'PDF-ontvangerslijst ingeschakeld op het handtekeningscherm van de klant',
  isRejectLoadsEnable: 'Ladingen afwijzen na aankomst op site inschakelen',
  rejectLoad_returnedMaterial: 'Workflowknop voor geretourneerd materiaal',
  rejectLoad_returnedAndFinalize: 'Workflowknoppen voor geretourneerd materiaal en voltooien',
  driverRejection: 'Lading weigeren',
  submitRejection: 'Verzenden Afwijzen',

  costAndPaymentsSummary: 'Samenvatting van kosten en betalingen',
  subtotal: 'Subtotaal',
  tax: 'Belasting',
  remainingBalance: 'Resterende saldo',
  paymentAmount: 'Te betalen bedrag',
  total: 'Totaal',
  paymentMethod: 'Betalingsmiddel',
  paymentProducts: 'Producten',
  unitPrice: 'Eenheid prijs',
  amount: 'Hoeveelheid',
  otherPaymentMethod: 'Andere betaalmethode',
  method: 'Methode',
  paymentOptions: 'Betalingsmogelijkheden',
  typeAdditionalInfo: 'Typ hier aanvullende informatie',
  COD: 'Contant bij levering',
  AMEX: 'American Express',
  CASH: 'Contant geld',
  CHECK: 'Rekening',
  'CREDIT CARD': 'Kredietkaart',
  ACCOUNT: 'Rekening openen',
  UNKNOWN: 'Onbekend',
  remainingMaterial: 'Resterend materiaal',
  splitLoad: 'Split Load - Multiple',
  isEnablePaymentAndCostWorkflows: 'Betaal- en kostenworkflows inschakelen',
  airTempValue: 'Luchttemperatuur',
  concreteTempValue: 'Beton Temperatuur',
  cylinderNumber: 'Cilindernummer',
  materialOnTruck: 'Materiaal op vrachtwagen',
  waterLocation: 'Waterlocatie',

  dotWorkflow: 'Batchdetails',
  inspectorSignature: 'Handtekening van de inspecteur',
  producerSignature: 'Handtekening van de producent',
  addedItems: 'Toegevoegde items',
  producerSignatureContact: 'Naam van de producent',
  inspectorSignatureContact: 'Naam van de inspecteur',
  waterAtPlant: 'Water op de fabriek (gl)',
  waterAtJobsite: 'Water op de werklocatie (gl)',
  totalActualWater: 'Totaal werkelijk water',
  timeDischarged: 'Tijd van lozing',
  ingredient: 'Ingrediënt',
  mcFac: 'MCFac',
  absFac: 'ABSFAC',
  od: 'OD',
  abs: 'ABS',
  ssd: 'SSD',
  freeMst: 'Vrij Vocht',
  cydsTarg: 'CYDS Doel',
  target: 'Doel',
  actual: 'Werkelijk',
  freWat: 'Vrij Water',
  trim: 'Trimmen',
  batchInformation: 'Batch Informatie',
  numberOfBatches: 'Aantal Batches',
  totalWater: 'Totaal Water',
  designWater: 'Ontwerp Water',
  toAdd: 'Toevoegen',
  designWC: 'Ontwerp W/C',
  waterCement: 'Water / Cement',
  waterInTruck: 'Water in Vrachtwagen',
  adjustWater: 'Water Aanpassen',
  trimWater: 'Water Trimmen',
  plantWeather: 'Weer op de Plant',
  rh: 'RH',
  observationTime: 'Observatietijd',
  address: 'Adres',
  plant: 'Plant',
  sp: 'S.P. #',
  beginTime: 'Starttijd',
  mixDesc: 'Mix Beschrijving',
  qtyOder: 'Hoeveelheid Bestelling',
  poTag: 'PO #',
  bridge: 'Brug #',
  volume: 'Volume',
  truckTag: 'Vrachtwagen #',
  qtyDelivered: 'Hoeveelheid Geleverd',
  qtyOrder: 'Hoeveelheid Bestelling',
  job: 'Werk #',
  batchRecord: 'Batchrecord',
  mixID: 'Mix ID',
  loads: 'Ladingen',
  isDot: 'Is DOT',
  tolStatus: 'TOL Status',
  lastUpdate: 'Laatste update',
  driverClickedFinalize: 'Chauffeur heeft op Voltooien geklikt',
  autoSubmit: 'Automatisch indienen',
  noTaxInformationIsAvailable: 'Geen belastinginformatie beschikbaar',
  retryPullTaxInformation: 'Belastinggegevens vernieuwen',
  var: '% Var',
  designQTY: 'Ontwerphoeveelheid',
  required: 'Vereist',
  batched: 'In batches',
  moisture: 'Vocht',
  actualWater: 'Werkelijk water',
  moisturePercent: '% Vocht',
  freeWater: 'Gratis water',
  reset: 'Reset',
  'at-plant': 'Bij Plant',
  'at-job': 'Bij baan',
};
